//
// Main
//


body {
  background-color: var(--kt-page-bg);
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

.customercard {
  position: relative;
  height: 285px;
  width: 455px;
}

.customeridcode {
  position: absolute;
  width: 100%;
  /* bottom: 135px; */
  color: white;
  font-size: 27px;
  font-weight: bold;
  text-transform: uppercase;
  top: 80%;
  left: 58%;
  transform: translate(-50%, -50%);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.customermobile {
  position: absolute;
  width: 100%;
  bottom: 80px;
  color: rgb(253, 0, 2);
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customername {
  position: absolute;
  width: 100%;
  bottom: 115px;
  color: rgb(253, 0, 2);
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.text-green {
  color: green;
}

.pclass1 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri;,sans-serif';
}

.sclass1 {
  font-size: 19px;
  line-height: 115%;
}

.pclass2 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri,sans-serif';
  text-align: center;
}

.sclass2 {
  font-size: 37px;
  line-height: 115%;
  color: #C00000;
}

.pclassComm {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri&quot;,sans-serif';
  text-align: 'justify';
}

.pclassComm2 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri&quot;,sans-serif';
}

.pclassComm3 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri&quot;,sans-serif';
  text-align: center;
}

.pclassComm4 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 10.0pt;
  margin-left: 0cm;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri;,sans-serif';
  text-align: center;
  text-indent: -1.65pt;

}

.hide {
  display: none;
}

.pclassComm5 {
  margin: 0cm 0cm 10pt 324pt;
  line-height: 115%;
  font-size: 15px;
  font-family: 'Calibri, sans-serif';
  text-align: center;
  text-indent: 36pt;
}

.spaCommo {
  font-size: 19px;
  line-height: 115%;
}

.spaCommo1 {
  font-size: 28px;
  line-height: 115%;
}

.fontColor {
  color: white;
}

.invoice {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
  position: relative;
}

.customerphoto {
  margin: 20px;
  height: 150px;
  width: 140px;
  padding: 50px;
  border: 1em;
  background: white;
  color: lightgrey;
  border: 1px solid black;
  display: table-cell;
  text-transform: uppercase;
  text-align: center;
  /* position: relative; */
}

.blackheadingline {
  /* margin: auto; */
  width: 100%;
  border: 3px solid black;
  background-color: black;
  margin-left: 10px;
  padding-left: 2px;
  color: white;
  font-weight: normal;
  text-align: center;
  font-size: 15px;
}

.tagheading {
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  padding-left: 35px;
  color: black;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
  display: table-cell;
}

.tagtextdata {
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  color: black;
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
  display: table-cell;
}

.imgcustomerphoto {
  margin: -52px;
  right: 50px;
  height: 150px;
  width: 140px;
  border: 2px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  /* background: white; */
}

.signature {
  margin: 20px;
  margin-top: 40px;
  min-height: 30px;
  width: 250px;
  border: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
  background: white;
  color: lightgrey;
  border: 1px solid black;
  display: table-cell;
  text-transform: uppercase;
  /* position: relative; */
}

.agreement {
  width: 105%;
  height: 100%;
  color: white;
  border: 1px solid;
  margin-left: -30px;
  padding-left: 2px;
}

.agreementimg {
  height: 100%;
  width: 100%;
  max-height: 100%;
  color: white;
  /* margin: 0cm 5cm 0cm 0cm; */
  margin-left: 10px;
  padding-left: 2px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


.customer-signaturedate {
  position: absolute;
  bottom: 285px;
  left: 120px;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  color: black;
  text-align: center;
  font-size: 13px;
  /* text-transform: initial; */
  display: table-cell;
}

.customer-signaturename2 {
  position: absolute;
  bottom: 285px;
  right: 90px;
  background-color: transparent;
  padding: 10px;
  color: black;
  text-align: center;
  font-size: 13px;
  border: 0.5px solid;
  /* text-transform: initial; */
  display: table-cell;
}

.singcenter {
  margin: 25px;
  margin-top: 40px;
  position: absolute;
  top: 25%;
  left: 45%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.p-3 {
  padding: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    padding-left: get($aside-config, width, desktop);

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, default, height, desktop);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }
  }

}

/* Remove Arrows from Input Type Number Start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Remove Arrows from Input Type Number End */